<template>
  <div>
    <section class="hero" v-if="role === 'Parent'">
      <div class="container stretch">
        <div class="columns is-multiline is-align-items-center z-2">
          <div class="column">
            <h1 class="hero__title mb-3">Members Area</h1>
            <div class="hero__content">
              <p class="mb-4 membersText">
                <strong
                  >Welcome {{ $auth.user.firstName }}, to your EstiaLabs Members
                  Area.</strong
                ><br />
                <span v-if="$auth.user.paymentType == 'ELNM'"
                  >Please click on the <i>"Book Teaching Sessions"</i> button
                  below to book or purchase more sessions.</span
                >
                <span v-else
                  >To access your {{ role }} Learning Platform, please click on
                  the <i>"{{ role }} Learning Platform"</i> button below.</span
                >
              </p>
              <p>
                <b-button
                  v-if="$auth.user.paymentType !== 'ELNM'"
                  class="membersBtn mb-2"
                  exact-active-class="active"
                  variant="primary"
                  id="tooltip-target-lpBtn"
                  href="#"
                  @click="redirectToPortal()"
                  >{{ role }} Learning Platform</b-button
                >
                <b-tooltip target="tooltip-target-lpBtn" triggers="hover"
                  >Click here to access your {{ role }} Learning Platform
                </b-tooltip>
              </p>
              <p>
                <b-button
                  v-if="
                    !$auth.loggedIn ||
                    $auth.user.grantedAuthorities.includes('PARENT')
                  "
                  class="membersBtn mb-2"
                  exact-active-class="active"
                  variant="primary"
                  id="tooltip-target-bookSessionsBtn"
                  :to="{ name: 'get-teaching-sessions' }"
                  >Book Teaching Sessions</b-button
                >
                <b-tooltip
                  target="tooltip-target-bookSessionsBtn"
                  triggers="hover"
                  >Click here to book or purchase more teaching sessions
                </b-tooltip>
              </p>
            </div>
          </div>
        </div>
        <img
          src="@/assets/slide_image_estia.png"
          alt=""
          class="is-hidden-mobile"
        />
      </div>
    </section>
    <b-container v-else class="text-center my-5">
      <b-spinner />
    </b-container>
  </div>
</template>

<script lang="ts">
import { stringify } from 'query-string';
import { defineComponent, getCurrentInstance, computed } from "vue";
import { getAuthHeaders } from '~~/utils/authorisation';

export default defineComponent({
  layout: "default",
  middleware: ["auth", "irisRedirect", 'nonMemberRedirect', "portaluser"],
  setup () {
    const instance = getCurrentInstance()
    const role = computed(() => instance.proxy.$auth.user.grantedAuthorities.includes('PARENT') ? 'Parent' : 'Student')

    const redirectToPortal = (replace: boolean = false) => {
      return getAuthHeaders(instance.proxy.$auth).then(({ Authorization }) => {
        const p = {
          commandStr: 'DoLogin',
          token: Authorization.split(' ')[1],
          direct: true,
          bUrl: '/'
        }
        return window.location[replace ? 'replace' : 'assign'](`/learningsystem/portal.direct?${stringify(p)}`)
      })
    }

    onMounted(() => {
      if (role.value === "Student") {
        return redirectToPortal(true)
      }
    });
    return {
      role,
      redirectToPortal
    };
  },
  head: {
    title: "EstiaLabs - Members Login",
  },
});
</script>

<style scoped>
.membersBtn {
  width: 14rem;
}
.hero {
  flex-direction: row;
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: block;
    margin-top: 3rem;
  }
}
</style>
